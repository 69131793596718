@import "reset";
@import "fonts";
@import "variables";

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

html {
  background-color: $accent-1;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Courier Prime", monospace;

  background-color: $accent-2;
  background-image: radial-gradient(at 50% 50%, $accent-2, $accent-1);
  color: $text-color-primary;

  font-size: 14px;

  --typewriter-cursor: "_";
}

main {
  width: 100vmin;
  height: 100vmin;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1em;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 6vmin;
    padding-bottom: 12vmin;
  }

  .profile-img-container {
    width: 50%;
    padding-top: 50%;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAIRlWElmTU0AKgAAAAgABQESAAMAAAABAAEAAAEaAAUAAAABAAAASgEbAAUAAAABAAAAUgEoAAMAAAABAAIAAIdpAAQAAAABAAAAWgAAAAAAAABIAAAAAQAAAEgAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAABCgAwAEAAAAAQAAABAAAAAAx28c8QAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDYuMC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KGV7hBwAAAyVJREFUOBEdk9tu40Qch7/x2c7JcY5t0nbbLUXaItitBEIgcYHENa/U5+ABuOUNECBxxSJYtq1CW7bbJG3SNGmcxHZOdsyA5mJmpNHvJ/2/b0TDLaflUgmv4JGzbAqOQ0EvsvPsiEqzSXAF/rjH07rDlX9N1+8znkzwpxPCMETTbBXD1rENnaxqYoUCa7UkECMGkwBzZKOM1/QnA9rjDsFqQRDNWMo9IUZdBuHpehEiVDCyJpV6juy2w/uoS/f2LT+cf09fGfJq7xWOlmEZ++iaRqqmJJsY8dJTU6HZbIQpQ2y2ax6fffM5Jzt7lGOVWCYPZXsUPCe+Uvi99ROt9QXdaMx4NkLbL2YpZCw008aPTULVYNerclTdQl3FIBR2Kg7nnQG//Nml+ElM5S6h/49PoWigqdkDMopFydTYt0ApW1RcjzdX77HnC7bKJYbzKUsl5cy/oCjWWNUdPsweEm0E2qroUKxW2MpkaCRNSnkHPW/T6z6wWacgZ/PmbYu9o2M+3f+KyExRXYtsHHDX76P+PYlOD+spRVeh9tzDq+fJOBZVxaC5VcMtFfioXsPRBZeLOUlGEK0nDMf3DHo3aC8qOXmZcWE4DFYXfPkyIj8qcPbzJYcvPiDeLKQHPoZbwLRTRo8PGMaCxfCW2aiNWq04p7vP9pmrDt/9eos+7bFXy1Ar71Kt1rEzsmDq0522mD21qLpzthoWasFh/t8MmjkbL+ehZwt8eyQlmfmsVqZsSUmjkPVqhSjCdmOH/OSY0rZ0wAzpRUPmcxngYBP3J0xdgSGZe4lNo1hn2Lnnx8E7XDfHUh8T+BWOGycMwy6t1i2vfzvn6SGRFAY+02CJG0TUFZWH9j1//XHGgVdked9BWAeIQOOm3WY5ztLuX3HduSE0SmjWGvExpEeNOvVcgW7/Ufo/YZQmfP3FCU0vj0TCtPdEJYFrqe7rWYSr50gUk+u7G8S+DHAViTCXZ7lJCFdryV7wLppjAkUEh9KRrGOSajrTfJn7Zfo/gcvZDFFFpHlSMvKxpgj5w2Aj/cmaOvE6QRECU9dQ5HqcR6jyvJZ6T2XRVMC/2mpjtHz2jQsAAAAASUVORK5CYII=");
    background-size: 100% 100%;
    border-radius: 50%;
    overflow: hidden;
    image-rendering: pixelated;
    position: relative;

    .profile-img {
      position: absolute;
      top: 0;
      width: 100%;
      height: auto;
      image-rendering: auto;

      &.unresolved {
        opacity: 0;
      }
    }
  }

  .name {
    margin-top: 1.618em;
    margin-bottom: 0.618em;
    text-align: center;
    font-family: "Major Mono Display", monospace;
  }

  .title {
    text-align: center;
    font-size: 0.8em;
    margin-bottom: 1em;
    color: $text-color-secondary;
  }

  .links {
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: $text-color-info;

    & svg {
      width: 1em;
      opacity: 0.5;
      transition: opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        opacity: 1;
      }
    }

    & > *:not(:last-child) {
      margin-right: 0.618em;
    }
  }
}

.notification {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 1rem;
  padding: 1rem;
  line-height: 1.5em;

  border-radius: 3px;
  background-color: #333;
  color: $accent-3;
  cursor: pointer;

  animation-name: appear;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  animation-duration: 0.3s;
  animation-iteration-count: 1;

  @media (min-width: 450px) {
    & {
      left: initial;
      max-width: 16rem;
    }
  }
}

@keyframes appear {
  from {
    transform: translateY(calc(1rem + 100%));
  }
}
