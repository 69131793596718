html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: Courier Prime;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(Courier Prime Regular), local(CourierPrime-Regular), url("https://fonts.gstatic.com/s/courierprime/v1/u-450q2lgwslOqpF_6gQ8kELaw9pWs39pvnRPA.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Courier Prime;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(Courier Prime Regular), local(CourierPrime-Regular), url("https://fonts.gstatic.com/s/courierprime/v1/u-450q2lgwslOqpF_6gQ8kELawFpWs39pvk.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Courier Prime;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(Courier Prime Bold), local(CourierPrime-Bold), url("https://fonts.gstatic.com/s/courierprime/v1/u-4k0q2lgwslOqpF_6gQ8kELY7pMT-7fq9PrNX2-Ag.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Courier Prime;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(Courier Prime Bold), local(CourierPrime-Bold), url("https://fonts.gstatic.com/s/courierprime/v1/u-4k0q2lgwslOqpF_6gQ8kELY7pMT-Dfq9PrNX0.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Major Mono Display;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(Major Mono Display Regular), local(MajorMonoDisplay-Regular), url("https://fonts.gstatic.com/s/majormonodisplay/v4/RWmVoLyb5fEqtsfBX9PDZIGr2tFubRh7A3eRAHRfwhRT.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Major Mono Display;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(Major Mono Display Regular), local(MajorMonoDisplay-Regular), url("https://fonts.gstatic.com/s/majormonodisplay/v4/RWmVoLyb5fEqtsfBX9PDZIGr2tFubRh7DXeRAHRfwg.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow: hidden;
}

html {
  background-color: #5c3a70;
}

body {
  color: #fff;
  --typewriter-cursor: "_";
  background-color: #d76d77;
  background-image: radial-gradient(#d76d77, #5c3a70);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Courier Prime, monospace;
  font-size: 14px;
  display: flex;
}

main {
  justify-content: center;
  align-items: center;
  width: 100vmin;
  height: 100vmin;
  font-size: 1em;
  display: flex;
}

main .wrapper {
  flex-direction: column;
  align-items: center;
  padding-bottom: 12vmin;
  font-size: 6vmin;
  display: flex;
}

main .profile-img-container {
  image-rendering: pixelated;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAIRlWElmTU0AKgAAAAgABQESAAMAAAABAAEAAAEaAAUAAAABAAAASgEbAAUAAAABAAAAUgEoAAMAAAABAAIAAIdpAAQAAAABAAAAWgAAAAAAAABIAAAAAQAAAEgAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAABCgAwAEAAAAAQAAABAAAAAAx28c8QAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDYuMC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KGV7hBwAAAyVJREFUOBEdk9tu40Qch7/x2c7JcY5t0nbbLUXaItitBEIgcYHENa/U5+ABuOUNECBxxSJYtq1CW7bbJG3SNGmcxHZOdsyA5mJmpNHvJ/2/b0TDLaflUgmv4JGzbAqOQ0EvsvPsiEqzSXAF/rjH07rDlX9N1+8znkzwpxPCMETTbBXD1rENnaxqYoUCa7UkECMGkwBzZKOM1/QnA9rjDsFqQRDNWMo9IUZdBuHpehEiVDCyJpV6juy2w/uoS/f2LT+cf09fGfJq7xWOlmEZ++iaRqqmJJsY8dJTU6HZbIQpQ2y2ax6fffM5Jzt7lGOVWCYPZXsUPCe+Uvi99ROt9QXdaMx4NkLbL2YpZCw008aPTULVYNerclTdQl3FIBR2Kg7nnQG//Nml+ElM5S6h/49PoWigqdkDMopFydTYt0ApW1RcjzdX77HnC7bKJYbzKUsl5cy/oCjWWNUdPsweEm0E2qroUKxW2MpkaCRNSnkHPW/T6z6wWacgZ/PmbYu9o2M+3f+KyExRXYtsHHDX76P+PYlOD+spRVeh9tzDq+fJOBZVxaC5VcMtFfioXsPRBZeLOUlGEK0nDMf3DHo3aC8qOXmZcWE4DFYXfPkyIj8qcPbzJYcvPiDeLKQHPoZbwLRTRo8PGMaCxfCW2aiNWq04p7vP9pmrDt/9eos+7bFXy1Ar71Kt1rEzsmDq0522mD21qLpzthoWasFh/t8MmjkbL+ehZwt8eyQlmfmsVqZsSUmjkPVqhSjCdmOH/OSY0rZ0wAzpRUPmcxngYBP3J0xdgSGZe4lNo1hn2Lnnx8E7XDfHUh8T+BWOGycMwy6t1i2vfzvn6SGRFAY+02CJG0TUFZWH9j1//XHGgVdked9BWAeIQOOm3WY5ztLuX3HduSE0SmjWGvExpEeNOvVcgW7/Ufo/YZQmfP3FCU0vj0TCtPdEJYFrqe7rWYSr50gUk+u7G8S+DHAViTCXZ7lJCFdryV7wLppjAkUEh9KRrGOSajrTfJn7Zfo/gcvZDFFFpHlSMvKxpgj5w2Aj/cmaOvE6QRECU9dQ5HqcR6jyvJZ6T2XRVMC/2mpjtHz2jQsAAAAASUVORK5CYII=");
  background-size: 100% 100%;
  border-radius: 50%;
  width: 50%;
  padding-top: 50%;
  position: relative;
  overflow: hidden;
}

main .profile-img-container .profile-img {
  image-rendering: auto;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
}

main .profile-img-container .profile-img.unresolved {
  opacity: 0;
}

main .name {
  text-align: center;
  margin-top: 1.618em;
  margin-bottom: .618em;
  font-family: Major Mono Display, monospace;
}

main .title {
  text-align: center;
  color: #ffffffb3;
  margin-bottom: 1em;
  font-size: .8em;
}

main .links {
  color: #ffffff80;
  flex-direction: row;
  justify-content: space-around;
  align-self: center;
  display: flex;
}

main .links svg {
  opacity: .5;
  width: 1em;
  transition: opacity .1s cubic-bezier(.4, 0, .2, 1);
}

main .links svg:hover {
  opacity: 1;
}

main .links > :not(:last-child) {
  margin-right: .618em;
}

.notification {
  color: #ffaf7b;
  cursor: pointer;
  background-color: #333;
  border-radius: 3px;
  margin: 1rem;
  padding: 1rem;
  line-height: 1.5em;
  animation-name: appear;
  animation-duration: .3s;
  animation-timing-function: cubic-bezier(0, 0, .2, 1);
  animation-iteration-count: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (width >= 450px) {
  .notification {
    left: initial;
    max-width: 16rem;
  }
}

@keyframes appear {
  from {
    transform: translateY(calc(1rem + 100%));
  }
}

/*# sourceMappingURL=index.f69c3671.css.map */
