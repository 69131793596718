$bg-color: #412772;
$accent-1: #5c3a70;
$accent-2: #d76d77;
$accent-3: #ffaf7b;

$base: #fff;
$text-color-primary: rgba($base, 1);
$text-color-secondary: rgba($base, 0.7);
$text-color-info: rgba($base, 0.5);
$text-color-divider: rgba($base, 0.2);
